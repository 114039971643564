import React, { useState } from "react"

import { Link } from "gatsby"

import SwiperCore, { Pagination, Navigation, A11y, Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import { Modal } from "react-bootstrap"

import "swiper/swiper.scss"

import "swiper/components/pagination/pagination.scss"
import "swiper/components/navigation/navigation.scss"

// import ovalScroll from "../../images/round-scroll.svg"
import { FaCaretDown } from "react-icons/fa";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HawkEyeLogo from "../../images/hawkeye-logo.png"

SwiperCore.use([Pagination, Navigation, A11y, Autoplay])

const HeroSection = () => {
  const [lgShow, setLgShow] = useState(false)
  // const [lgShowCert, setLgShowCert] = useState(false)

  return (
    <>
      <div className="hero-wrapper" id="hero">
        <Swiper
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: true,
          }}
          preventInteractionOnTransition={true}
          navigation
          onClick={swiper => swiper.autoplay.stop()}
        >
          <SwiperSlide>
            <div className="slides first-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="text-center mb-3 hero-uppermost-text">
                      USE OUR EXPERTISE TO YOUR ADVANTAGE
                    </p>
                  </div>
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">Welcome to</p>
                      <h1 className="hero-title-text">GRAY HAWK</h1>
                      <h2 className="hero-subtitle-text">Land Solutions</h2>
                      <p className="hero-lead-text mt-5 mb-0">
                        Gray Hawk Land Solutions provides Right of Way and Title
                        services for oil and gas producers, midstream companies,
                        utility and energy firms, and more. We can do title
                        searches, audits, and curation for you. We also provide
                        digital assistance and other real estate services for
                        the success of your business. Take full advantage of our
                        years of expertise and knowledge.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/gray-hawk-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides second-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="text-center mb-3 hero-uppermost-text">
                      USE OUR EXPERTISE TO YOUR ADVANTAGE
                    </p>
                  </div>
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">MORE THAN</p>
                      <h1 className="hero-title-text">ARTIFICIAL</h1>
                      <h1 className="hero-title-text">INTELLIGENCE</h1>
                      <h2 className="hero-subtitle-text">
                        Automated digital
                        <br />
                        data extraction
                      </h2>
                      <div className="row">
                        <div className="col-lg-4 col-md-8 ml-lg-2 ml-md-2 align-self-center">
                          <img
                            src={HawkEyeLogo}
                            alt="HawkEye Logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-5 my-lg-5 my-md-5 my-5">
                          <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/oHzXzk96wHw"
                            title="Hawkeye Powered by Grooper"
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                              border: "2px solid rgb(249, 115, 11)",
                              borderRadius: "5px",
                              height: "350px",
                            }}
                          />
                        </div>
                        <div className="col-lg-12">
                          <p className="hero-lead-text mb-0">
                            Using machine learning, artificial intelligence, and
                            our expertise we help companies turn physical
                            records into digital data, and distributed datasets
                            into cohesive reports.
                          </p>
                        </div>
                      </div>
                    </div>

                    <button
                      className="submit-btn mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 border-0"
                      onClick={() => setLgShow(true)}
                    >
                      HOW IT WORKS!
                    </button>

                    <Link to="/artificial-intelligence" className="submit-btn">
                      Learn More
                    </Link>

                    <Modal
                      size="lg"
                      show={lgShow}
                      onHide={() => setLgShow(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                      className="modal-wrapper"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <div className="services-description-box mt-3">
                          <iframe
                            title="How it works"
                            width="100%"
                            height="500px"
                            allowFullScreen="allowFullScreen"
                            src="https://www.youtube.com/embed/3Nt67t0oBeM"
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          ></iframe>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="col-lg-2 social-media-box mt-lg-0 mt-md-5 mt-5">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/gray-hawk-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides third-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="text-center mb-3 hero-uppermost-text">
                      USE OUR EXPERTISE TO YOUR ADVANTAGE
                    </p>
                    {/* <div className="cdbe-state-wrapper">
                      <h5 className="text-center mb-3 cdbe-state">
                        CERTIFIED DISADVANTAGED BUSINESS ENTITY
                      </h5>
                      <Dropdown className="mt-2">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          IN THE STATE OF
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Cert_Letter_for_the_State_of_Hawaii.pdf`}
                          >
                            hawaii
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Approval_from_the_State_of_California.pdf`}
                          >
                            california
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Certification_Letter_from_Oregon_DOT.pdf`}
                          >
                            oregon
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Certification_Letter_from_AZDOT.pdf`}
                          >
                            arizona
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Certification_Letter_from_CDOT.pdf`}
                          >
                            colorado
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/DBE_Certification_Letter_from_Oklahoma_DOT.pdf`}
                          >
                            oklahoma
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/01_DBE_ Approved_App_PacCoasLandSol.pdf`}
                          >
                            texas
                          </Dropdown.Item>
                          <Dropdown.Item
                            target="_blank"
                            href={`../../files/Certificate_for_Supplier_Clearinghouse_for_the_State_of_California-Minority_Business_Enterprise.pdf`}
                          >
                            cpuc
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </div>
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">
                        from our trusted partner
                      </p>
                      <h1 className="hero-title-text no-caps">
                       Irth Solutions
                      </h1>
                      <h2 className="hero-subtitle-text">
                      Land & 
                        <br />
                        ROW
                        
                      </h2>
                      <h3> Management Software</h3>
                      <p className="hero-lead-text mt-5 mb-0">
                      Irth's market-leading SaaS platform improves resilience and reduces risk in the sustainable delivery of essential services that millions of people and businesses rely on every day. Energy, utility, and telecom companies across the U.S. and Canada trust Irth for damage prevention, training, asset inspections, and land management solutions.
                      </p>
                      <a
                        href="https://youtu.be/pLnARjjiIb4"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn  mt-5 mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 no-caps"
                      >
                      
                        WATCH THE VIDEO
                      </a>
                      <a
                        href="https://www.irthsolutions.com/land-management/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn  mt-5 mr-lg-5 mr-md-5 mb-md-0 mb-3 mr-0 no-caps"
                      >
                        <span className="mt-5">
                          Learn about land acquisition software
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/gray-hawk-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                      {/* <Link to="/" className="vertical-text">
                        twitter
                      </Link>
                      <Link to="/" className="vertical-text">
                        instragam
                      </Link>
                      <Link to="/" className="vertical-text">
                        facebook
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides nello-slide">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="text-center mb-3 hero-uppermost-text">
                      USE OUR EXPERTISE TO YOUR ADVANTAGE
                    </p>
                  </div>
                  <div className="col-lg-1">
                    <div className="horizontal-line" />
                  </div>
                  <div className="col-lg-9">
                    <div className="gray-hawk-wrapper">
                      <p className="hero-welcome-text mb-3">
                        authorized seller for
                      </p>
                      <h1 className="hero-title-text">NELLO Inc</h1>
                      <h2 className="hero-subtitle-text">
                        Communication
                        <br />
                        Tower Manufacturer
                      </h2>

                      <p className="hero-lead-text mt-5 mb-0">
                        NELLO is a global leader in the design and fabrication
                        of galvanized steel towers and poles. We service a wide
                        variety of markets specializing in tapered utility pole
                        and transmission pole structures as well as wireless
                        self-supporting towers, guyed towers, and monopoles.
                        Lead by a team of experienced engineering, design, and
                        manufacturing professionals, NELLO has grown to one of
                        the larger infrastructure providers in North America.
                        Today, we continue to add manufacturing and distribution
                        facilities to meet the needs of our growing customer
                        base. Our extensive design and manufacturing experience
                        allows us to develop new cost effective and innovative
                        products for our customers.
                      </p>
                      <a
                        href="https://www.nelloinc.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="submit-btn mt-5 mb-md-0 mb-3"
                      >
                        NAVIGATE TO NELLO Inc
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-2 social-media-box">
                    <div className="social-media-links">
                      <div className="vertical-line" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/gray-hawk-land-solutions/"
                        className="vertical-text"
                      >
                        linkedin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="d-flex align-items-center justify-content-center flex-column scroll-btn-wrapper">
            <Link to="#about-section" className="scroll-btn">
              <FaCaretDown className="text-white caret-down"
              />
            </Link>
            <p className="hidden-text mt-2">about us</p>
          </div>
        </Swiper>
        {/* <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Link to="#about-section" className="scroll-btn">
              <FontAwesomeIcon
                icon={faCaretDown}
                className="text-white caret-down"
              />
            </Link>
            <p className="hidden-text mt-2">about us</p>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default HeroSection
