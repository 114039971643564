import React, { useState } from "react"

import { Modal } from "react-bootstrap"

import headShotFull1 from "../../images/our-team/cash-diel-full.jpg"
import headShotFull2 from "../../images/our-team/eli-lyons-full.jpg"
import headShotFull3 from "../../images/our-team/troy-schell-full.jpg"
import headShotFull4 from "../../images/our-team/danny-p-gibbs-full.jpg"

import headShot1 from "../../images/our-team/cash-diel.png"
import headShot2 from "../../images/our-team/eli-lyonis.png"
import headShot3 from "../../images/our-team/troy-schell.png"
import headShot4 from "../../images/our-team/danny-p-gibbs.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaEnvelope } from "react-icons/fa";
// import { faPhone } from "@fortawesome/free-solid-svg-icons"

const VerticallyCenteredModal1 = props => {
  const [modalShow1, setModalShow1] = useState(false)
  const [mid, setMid] = useState(1)
  const handleClose = () => {
    setModalShow1(false)
    setMid(1)
  }

  const handleShow = () => {
    setModalShow1(true)
    setMid(
      props.photoButton1
        ? 1
        : props.photoButton2
        ? 2
        : props.photoButton3
        ? 3
        : props.photoButton4
        ? 4
        : null
    )
  }

  const teams = [
    {
      id: 1,
      headshot: headShotFull1,
      name: "Cash I. Diel",
      position: "President",
      // number: "+13451551651",
      email: "cash@grayhawkland.com",
      description:
        "<p>Cash Diel is the co-founder of Gray Hawk Land Solutions and brings 16 years of industry experience in all aspects of the Right of Way and Oil & Gas fields to his position.</p><p>Most recently, Mr. Diel was in the position of Director of Field Services at a national energy consulting firm that offered services in Right of Way, survey, environmental, engineering, and numerous other offerings. In this role, Mr. Diel was responsible for starting the Right of Way department and continued the development of the business throughout his tenure.</p><p>In addition, he oversaw the execution of major projects. Mr. Diel has been involved in all sizes and types of projects, including pipeline, electric transmission, oil & gas, renewable energy, and midstream.</p><p>Mr. Diel is responsible for the management of all the firm’s active projects and employees. He oversees recruiting, hiring, and the company’s external operations. Additionally, Mr. Diel is responsible for the management of all client relationships.</p><p>Mr. Diel earned a Bachelor’s in Agricultural Sciences and Natural Resources with a Major in Agricultural Economics from Oklahoma State University. He has a Juris Doctorate from the University of Oklahoma College of Law and is a licensed attorney in the State of Oklahoma.Mr. Diel is a member of the IRWA, IRWA Pipeline Committee and currently serves as Treasurer of the Right of Way Consultants Council.</p>",
    },
    {
      id: 2,
      headshot: headShotFull2,
      name: "Eli Lyons",
      position: "VP of Business Development & Operations",
      // number: "+13451551651",
      email: "eli@grayhawkland.com",
      description:
        "<p>Eli Lyons brings 19 years of experience as an owner and as a senior executive of regional and national companies to his position. Prior to co-founding Gray Hawk Land Solutions, Mr. Lyons held the position of Vice President of Business Development at geoAMPS, LLC where he helped to introduce and implement the most state of the art technology available to the Right of Way industry.</p><p>His efforts at geoAMPS helped them make the Forbes list two years in a row and helped thousands of users to leverage technology to become more efficient at their jobs. Mr. Lyons held the same position for a national right of way company specializing in the pipeline and midstream sectors for 5 years prior to joining geoAMPS.</p><p>His efforts helped the firm see exponential growth in that time period growing from 15 right of way agents to over 300. Mr. Lyons is responsible for the firm’s Business Development efforts nationwide as well as our internal operations. He works with our Senior Executive Staff for our clients’ logistical and operational needs. Mr. Lyons is actively engaged in the management of our existing projects.</p><p>Mr. Lyons earned his Master of Science in Engineering & Technology Management from the Colorado School of Mines. He is fluent in English and Spanish, is an active member of the IRWA and holds a managing broker’s license in the State of Colorado.</p>",
    },
    {
      id: 3,
      headshot: headShotFull3,
      name: "Troy A. Schell",
      position: "VP of Legal",
      // number: "+13451551651",
      email: "troy@grayhawkland.com",
      description:
        "<p>Troy A. Schell is the Principle of the Law Offices of Troy A. Schell located in Los Angeles County, California. Practicing for nearly twenty (20) years, Mr. Schell has focused his legal practice extensively on Mergers and Acquisitions in the Private Sector in various industries including Healthcare, Construction, and Real Estate.</p><p>Prior to starting his own practice, Mr. Schell was General Counsel for one of the largest healthcare companies in the Country and was responsible for the acquisition of nearly two dozen hospitals in a multitude of States including, but not limited to, California, Texas, Kansas, Missouri, and Georgia.</p><p>Mr. Schell earned his Juris Doctorate Degree from Pepperdine University in 2000. He has been licensed to practice in the State of California since 2000. Mr. Schell is also a member of several organizations and is a frequent speaker on various legal topics related to business and regulatory issues.</p>",
    },
    {
      id: 4,
      headshot: headShotFull4,
      name: "Danny P. Gibbs",
      position: "Director of Business Development",
      description:
        "<p>Danny Gibbs brings more than 30 years of energy industry knowledge and about 20 years of leadership experience to Gray Hawk Land Solutions.</p><p>At Spectra Energy, Danny served as Director of ROW Acquisition for the company’s capital expansion projects and also held the role of Director of Public Affairs - U.S. natural gas pipelines. Danny was integral in the development and implementation of noteworthy communications training and advocacy positions including Spectra’s Ambassador program and the Eight Commitments to Landowners (the “Landowner Bill of Rights”) adopted by Interstate Natural Gas Association of America companies in 2008. An award-winning communicator and Fortune 500 company spokesman, Danny is also an innovative team-building manager and passionate advocate of hydrocarbon energy and other infrastructure development projects. Danny has trained hundreds of energy company representatives to be better ambassadors for the industry.</p><p>Mr. Danny P. Gibbs earned a Bachelor of Arts in Journalism with a Public Relations emphasis from Texas Tech University. He is an active participant of the IRWA and is a member of the IRWA Pipeline Committee. </p>",
    },
  ]

  const filterdData = teams.filter(team => team.id === mid)

  // console.log(filterdData, "test")

  return (
    <>
      <div className="team-modal-box">
        <button onClick={handleShow}>
          {props.photoButton1 ? (
            <img
              className="img-fluid mb-3"
              src={headShot1}
              alt={filterdData[0].name}
            />
          ) : props.photoButton2 ? (
            <img
              className="img-fluid mb-3"
              src={headShot2}
              alt={filterdData[0].name}
            />
          ) : props.photoButton3 ? (
            <img
              className="img-fluid mb-3"
              src={headShot3}
              alt={filterdData[0].name}
            />
          ) : props.photoButton4 ? (
            <img
              className="img-fluid mb-3"
              src={headShot4}
              alt={filterdData[0].name}
            />
          ) : null}
        </button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow1}
          onHide={handleClose}
          className="modal-wrapper"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }} />
          <Modal.Body>
            <div className="m-wrap text-center mb-5">
              <img
                className="img-fluid"
                src={filterdData[0].headshot}
                alt={filterdData[0].name}
              />
            </div>
            <h2 className="text-center">{filterdData[0].name}</h2>
            <p className="text-center personnel-position">
              {filterdData[0].position}
            </p>
            {filterdData[0].email ? (
              <div
                className="d-flex justify-content-center mx-auto"
                style={{ width: "60%" }}
              >
                <div className="d-flex align-items-center">
                  <FaEnvelope
                  
                    className="text-white caret-down"
                    style={{ fontSize: "1rem" }}
                  />
                  <a
                    href={`mailto:${filterdData[0].email}`}
                    className="text-white m-0 ml-2"
                  >
                    {filterdData[0].email}
                  </a>
                </div>
              </div>
            ) : null}

            <div
              className="team-description-box mt-5"
              dangerouslySetInnerHTML={{ __html: filterdData[0].description }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default VerticallyCenteredModal1
