import React from "react"

// import { Link } from "gatsby"

// import SwiperCore, { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"

// import "swiper/components/pagination/pagination.scss"

import ChooseUs from "../why-us/why-us"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"

// SwiperCore.use([Pagination])

const AboutSection = () => {
  // const swiperRef = useRef(null)

  // const goNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext()
  //   }
  // }
  // const goPrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev()
  //   }
  // }
  return (
    <>
      <div className="about-section-wrapper" id="about-section">
        <Swiper
          // spaceBetween={20}
          slidesPerView={1}
          // pagination={{ clickable: true }}
          // ref={swiperRef}
        >
          <SwiperSlide>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-1">
                  <div className="horizontal-line" />
                </div>
                <div className="col-lg-6">
                  <p className="title mb-0">about</p>
                  <h2 className="brand">Gray Hawk</h2>
                  <h2 className="brand-services text-white">Land Solutions</h2>
                  <p className="phrase">Why You Should Partner with Us</p>
                  <p className="lead mb-0 mt-5 text-justify">
                    {/* Gray Hawk Land Solutions was formed with our clients in
                    mind. We have taken years of knowledge and expertise within
                    the Right of Way profession to form a comprehensive
                    organization that you can count on for all your Right of Way
                    needs. Gray Hawk can assist with the initial planning of
                    projects all the way through cleanup and restoration. Our
                    highly skilled and dedicated staff of professionals are
                    accomplished in all aspects of project development and
                    execution. We are committed to the details of each project,
                    which ensures success and provides our clients with the most
                    positive outcome. */}
                    We offer agile services, better pricing, strategic
                    partnerships, and the convenience of artificial intelligence
                    data extraction. Our main goal is to provide the best
                    overall service to our clients. Let’s discuss how we can
                    best help your company achieve its goals.
                  </p>
                  {/* <p className="lead mt-5 mb-0">
                    No other business offers the kind of consistency,
                    efficiency, or comprehensive support Gray Hawk offers.
                  </p> */}

                  {/* <Link to="/" type="button" className="request-btn mt-5">
                    READ MORE
                  </Link> */}
                </div>
                <div className="col-lg-5">
                  {/* <FontAwesomeIcon
                      icon={faPlayCircle}
                      className="text-white caret-down"
                      style={{ fontSize: "8rem" }}
                    />
                    <p className="text-white mt-3">watch video</p> */}
                  {/* <iframe
                    className="mt-5"
                    title="Gray Hawk"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/vOvwJE8Xuwk"
                    modestBranding="1"
                    showInfo="0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullScreen
                  ></iframe> */}
                  {/* <iframe
                    className="mt-5"
                    id="ytplayer"
                    title="Gray Hawk"
                    type="text/html"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/vOvwJE8Xuwk?rel=0&enablejsapi=1&modestbranding=1"
                    frameborder="0"
                    allowFullScreen
                  /> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div className="container-fluid">
          <div className="text-right mt-4">
            <button className="mr-3" onClick={goPrev}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-left-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"
                />
              </svg>
              <div>PREV</div>
            </button>
            <button onClick={goNext}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-arrowRight-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
                />
              </svg>
              <div>NEXT</div>
            </button>
          </div>
        </div> */}
        <ChooseUs />
      </div>
    </>
  )
}

export default AboutSection
