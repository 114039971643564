import React from "react"
import { Link } from "gatsby"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FaCaretUp } from "react-icons/fa6";

import mountainsInMap from "../../images/contact-us/colorado-mountain.jpg"

import ContactForm from "@webriq/gatsby-webriq-form"

const ContactSection = () => {
  return (
    <>
      <div className="contact-wrapper" id="contact">
        <div
          className="container-fluid"
          // style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <div className="row">
            <div className="col-lg-2">
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="title vertical-text">contact</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="main-title">contact us</h3>
              <p className="phrase">COAST-TO-COAST CONFIDENCE</p>
              <p className="lead mt-5 mb-5 text-justify">
                Gray Hawk Land Solutions was formed with our clients in mind. We
                have taken years of knowledge and expertise within the Right of
                Way profession to form a comprehensive organization that you can
                count on for all your Right of Way needs.
              </p>
              <ContactForm
                data-form-id="1aaedcd0-d047-4628-ab41-420e05f79662"
                name="Contact Form"
                className="contact-form"
              >
                <div className="form-wrapper">
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Your Name"
                        id="full_name"
                        placeholder="YOUR NAME"
                      />
                      <input
                        className="form-control"
                        type="email"
                        name="Email"
                        id="email_add"
                        placeholder="EMAIL"
                      />
                      <input
                        className="form-control"
                        type="text"
                        name="Subject"
                        id="subject"
                        placeholder="SUBJECT"
                      />
                      <textarea
                        className="form-control"
                        type="text"
                        name="Message"
                        id="your_message"
                        cols="3"
                        rows="3"
                        placeholder="YOUR MESSAGE"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        className="btn hvr-shadow submit-btn"
                        type="submit"
                      >
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
              </ContactForm>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Link to="#" className="scroll-btn-reverse">
          <FaCaretUp  className="text-white caret-down" />
           
          </Link>
          <p className="hidden-text mt-2">to the topmost</p>
        </div>
      </div>
      <div className="colorado-mountains">
        <img
          src={mountainsInMap}
          alt="Colorado Mountains"
          className="img-fluid w-100"
        />
      </div>
    </>
  )
}

export default ContactSection
