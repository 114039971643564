import React from "react"
import { Link } from "gatsby"

const WhatToExpect = () => {
  return (
    <>
      <div className="col-lg-10 mx-auto px-0">
        <h2>What to Expect from Us</h2>
        <p className="main-lead mt-5 mb-0 text-justify">
          We provide a comprehensive list of services. Let us help you get your
          project off the ground and manage it through to completion.
        </p>
        <p className="main-lead mt-0 text-justify">
          Expect high-quality assistance and smart solutions in these areas:
        </p>
        <div className="mt-5">
          <ul className="d-flex w-100 justify-content-between list-wrapper white-hexagon">
            <div>
              <li>
                <Link to="/acquisitions/">Acquisitions</Link>
              </li>
              <li>
                <Link to="/curative-title/">Curative Title</Link>
              </li>
              <li>
                <Link to="/artificial-intelligence/">
                  Digital Data Extraction
                </Link>
              </li>
              <li>
                <Link to="/insiders-guide-to-successful-document-conversion-and-data-migration/">Document Scanning Services</Link>
              </li>
              <li>
                <Link to="/land-database-management/">
                  Land Database Management
                </Link>
              </li>              
            </div>
            <div>
              <li>
                <Link to="/land-management-systems/">
                  Land Management Systems
                </Link>
              </li>
              <li>
                <Link to="/linear-projects/">Linear Projects</Link>
              </li>
              <li>
                <Link to="/project-management-database/">
                  Project Management Database
                </Link>
              </li>
              <li>
                <Link to="/public-works/">Public Works</Link>
              </li>
              <li>
                <Link to="/real-estate/">Real Estate</Link>
              </li>
            </div>
            <div>
              <li>
                <Link to="/relocation/">Relocation</Link>
              </li>
              <li>
                <Link to="/right-of-way/">Right of Way</Link>
              </li>
              <li>
                <Link to="/title-audit/">Title Audit</Link>
              </li>
              <li>
                <Link to="/title-searches/">Title Searches</Link>
              </li>   
            </div>
          </ul>
        </div>
        <p className="main-lead mb-5 mt-5 text-justify">
          Gray Hawk Land Solutions works with midstream companies, oil & gas
          producers, pipeline companies, utility companies, renewable energy
          companies, and energy companies to streamline their projects and sort
          out any land-related issues.
        </p>
      </div>
    </>
  )
}

export default WhatToExpect
