import React from "react"
import { Link } from "gatsby"

// import { Modal } from "react-bootstrap"
// import pipelinesImg from "../../images/our-services/pipelines.png"
import OtherServices from "../our-services/other-services"

import pipelinesImg from "../../images/our-services/pipelines.png"
import elecTransmissionImg from "../../images/our-services/electric-transmission.png"
import renewEnergyImg from "../../images/our-services/renewable-energy.png"
import publicWorksImg from "../../images/our-services/public-works.png"
import pandellImg from "../../images/our-services/pandell.png"
import irth from "../../images/irth.png"
import artificialIntImg from "../../images/our-services/artificial-intelligence.png"

const Services = () => {
  // const [lgShow, setLgShow] = useState(false)
  // const [lgShow1, setLgShow1] = useState(false)
  // const [lgShow2, setLgShow2] = useState(false)
  // const [lgShow3, setLgShow3] = useState(false)
  // const [lgShow4, setLgShow4] = useState(false)
  // const [lgShow5, setLgShow5] = useState(false)
  return (
    <>
      <div className="services-wrapper" id="services">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <h3 className="title text-center">services</h3>
              <p className="lead text-center mt-5">
                No other business offers the kind of consistency, efficiency or
                comprehensive support Gray Hawk offers. Our people, process &
                technology are one of a kind.
              </p>
            </div>
            <div className="col-lg-10 mx-auto">
              <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex services-wrapper-1">
                  <div className="services-box">
                    <Link to="/pipelines">
                      <div className="hexa-wrapper hexa-pipeline d-flex justify-content-center align-items-center">
                        <img
                          loading="lazy"
                          src={pipelinesImg}
                          alt="pipelines"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>pipelines</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>

                    {/*
                    <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow}
                      onHide={() => setLgShow(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Pipelines</h3>
                        <div className="services-description-box mt-3">
                          <p className="text-white">
                            Gray Hawk Land Solutions provides clients with an
                            expert Right of Way team quipped with diverse skills
                            to meet the various challenges of our industry.
                            Because of our experience, we leverage our knowledge
                            to reduce risk. We’re constantly looking for new
                            advancements and technologies to find the smartest
                            and most optimal solutions to successfully complete
                            your projects from initial planning through
                            restoration.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                    */}
                  </div>
                  <div className="services-box">
                    <Link to="/electric-transmission">
                      <div className="hexa-wrapper hexa-electransmission d-flex justify-content-center align-items-center">
                        <img
                          loading="lazy"
                          src={elecTransmissionImg}
                          alt="electric transmission"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Electric
                            <br />
                            Transmission
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>

                    {/*
                    <Modal
                      size="lg"
                      show={lgShow1}
                      onHide={() => setLgShow1(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                      className="modal-wrapper"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Electric Transmission</h3>
                        <div className="services-description-box mt-3">
                          <p className="mt-3">
                            Large-scale Transmission projects often require an
                            experienced and proven Right of Way partner. As part
                            of our commitment to this sector, we provide this
                            quality service for all clients who require it. Our
                            team is flexible, knowledgeable, and takes all
                            environmental considerations into account to
                            minimize environmental damage.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>*/}
                  </div>
                  <div className="services-box">
                    <Link to="/renewable-energy">
                      <div className="hexa-wrapper hexa-renew d-flex justify-content-center align-items-center">
                        <img
                          loading="lazy"
                          src={renewEnergyImg}
                          alt="Renewable Energy"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Renewable
                            <br />
                            Energy
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>

                    {/*
                    <Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow2}
                      onHide={() => setLgShow2(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Renewable Energy</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            Large-scale wind & solar projects often require
                            Right of Way professionals with specific experience.
                            As part of our commitment to socially-responsible
                            power generation, we provide our safe and stable
                            specialized service for all clients who require it.
                            Our team is flexible and takes all environmental
                            considerations into account to minimize
                            environmental damage.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                    */}
                  </div>
                </div>
                <div className="d-flex services-wrapper-2">
                  <div className="services-box">
                    <Link to="/public-works-right-of-way">
                      <div className="hexa-wrapper hexa-pubworks d-flex justify-content-center align-items-center">
                        <img
                          loading="lazy"
                          src={publicWorksImg}
                          alt="Public Works"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>Public Works</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>

                    {/*<Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow3}
                      onHide={() => setLgShow3(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Public Works</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            Our managerial oversight and quality assurance
                            measures ensure that your projects are completed
                            on-time, within budget, and adhere to all regulatory
                            mandates.
                          </p>
                          <p>
                            Gray Hawk Land Solutions provides our DOT, State and
                            Federal clients with an expert Right of Way team
                            equipped with diverse skills to meet the various
                            challenges of the public works space. Because of our
                            experience, we leverage our knowledge to reduce
                            risk. We’re constantly looking for new advancements
                            and technologies to find the smartest and most
                            optimal solutions to successfully complete your
                            projects.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>*/}
                  </div>
                  <div className="services-box">
                    <a
                      href="https://www.irthsolutions.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="hexa-wrapper hexa-pandell d-flex justify-content-center align-items-center">
                        <img loading="lazy" src={pandellImg} alt="Irth" />
                        <div className="overlay" />
                        <div className="text-center text-wrapper no-caps">
                          <p>IRTH SOLUTIONS</p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </a>

                    {/*<Modal
                      className="modal-wrapper"
                      size="lg"
                      show={lgShow4}
                      onHide={() => setLgShow4(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3 className="no-caps">geoAMPS</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            Gray Hawk maintains a strategic alignment with
                            geoAMPS and top Survey firms to provide the best
                            overall service to our clients.
                          </p>
                          <img
                            src={require("../../images/geoAMPS_certified.png")}
                            className="img-fluid my-4 d-block"
                            alt="geoAMPS certification"
                          />
                          <a
                            href="/files/Certified-Preferred-Partner_GreyHawk.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="white-transparent-btn d-inline-block my-3"
                          >
                            View Certification
                          </a>
                        </div>
                      </Modal.Body>
                    </Modal>*/}
                  </div>
                </div>
                <div className="d-flex services-wrapper-3">
                  <div className="services-box">
                    {/* <button onClick={() => setLgShow5(true)}> */}
                    <Link to="/artificial-intelligence">
                      <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                        <img
                          loading="lazy"
                          src={artificialIntImg}
                          alt="Artificial Intelligence"
                        />
                        <div className="overlay" />
                        <div className="text-center text-wrapper">
                          <p>
                            Automated Digital Data Extraction Using Artificial
                            Intelligence
                          </p>
                          <span className="mt-5">learn more</span>
                        </div>
                      </div>
                    </Link>
                    {/* </button> */}

                    {/* <Modal
                      size="lg"
                      show={lgShow5}
                      onHide={() => setLgShow5(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered
                      className="modal-wrapper"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                      />
                      <Modal.Body>
                        <h3>Artificial Intelligence</h3>
                        <div className="services-description-box mt-3">
                          <p>
                            Using machine learning, artificial intelligence, and
                            our expertise - we help companies turn physical
                            records into digital data and distributed datasets
                            into cohesive reports.
                          </p>
                          <iframe
                            width="100%"
                            height="500px"
                            allowFullScreen
                            src="https://www.youtube.com/embed/vOvwJE8Xuwk"
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="Gray Hawk ROW Analyzer"
                          ></iframe>
                        </div>
                      </Modal.Body>
                    </Modal> */}
                  </div>
                </div>
                {/* <div
                  className="mx-auto"
                  style={{ width: "100%", maxWidth: "700px", height: "400px" }}
                >
                  <iframe
                    className="mt-5"
                    id="ytplayer"
                    title="Gray Hawk"
                    type="text/html"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/vOvwJE8Xuwk?rel=0&enablejsapi=1&modestbranding=1"
                    frameborder="0"
                    allowFullScreen
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OtherServices />
    </>
  )
}

export default Services
