import React from "react"
// import { Link } from "gatsby"

const RequestQuote = () => {
  return (
    <>
      <div className="request-quote-wrapper">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-lg-1">
              <div
                className="horizontal-line"
                style={{ visibility: "hidden" }}
              />
            </div> */}
            <div className="col-lg-12 text-center">
              <h3 className="title">WE ARE GRAY HAWK</h3>
              <p className="mt-4 mb-0 lead">
                Expertise, professionalism, reliability and a strong work
                ethic....that is the Gray Hawk promise to our clients.
              </p>
              <p className="lead mt-4">
                Expect comprehensive knowledge and assistance for all your Right
                of Way and Land Title needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestQuote
