import React from "react"
import { Link } from "gatsby"

import peopleImg from "../../images/why-us/people.png"
import gearImg from "../../images/why-us/gear.png"
import gadgetImg from "../../images/why-us/gadget.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FaCaretDown } from "react-icons/fa";

import WhatToExpect from "./what-to-expect"

// import { Modal } from "react-bootstrap"

const ChooseUs = () => {
  // const [lgShow, setLgShow] = useState(false)
  return (
    <>
      <div className="why-us-wrapper">
        <div className="container-fluid">
          <WhatToExpect />
          <div className="col-lg-10 mx-auto mt-5 px-0">
            <h3 className="main-title text-center">Why Work with Gray Hawk?</h3>
            <p className="main-lead text-center mt-3">
              Gray Hawk can assist with the initial planning of projects all the
              way through cleanup and restoration. Our highly skilled and
              dedicated staff of professionals are accomplished in all aspects
              of project development and execution. We are committed to the
              details of each project, which ensures success and provides our
              clients with the most positive outcome.
            </p>
          </div>
          <div className="col-lg-10 mx-auto mt-5 px-0 why-work-box">
            <div className="row" style={{ marginTop: "5rem" }}>
              <div className="col-lg-4 text-center work-box-1">
                <img loading="lazy" src={peopleImg} alt="Experienced People" />
                <h5 className="mt-5 inner-title">
                  <Link to="/partnerships">Partnerships</Link>
                </h5>
                <p className="inner-lead px-lg-3">
                  Strategic alignment with Irth Solutions and top Survey firms to
                  provide the best overall service to our clients.
                </p>
              </div>
              {/* <div className="col-lg-3 text-center">
                <img src={gearImg} alt="Experienced People" />
                <h5 className="mt-5 inner-title">Pricing</h5>
                <p className="inner-lead">
                  We are a better value than our competitors. We can offer
                  one-off rates to our clients in order to make individuals fit
                  necessary parameters that our clients need to operate in.
                </p>
              </div> */}
              <div className="col-lg-4 text-center work-box-2">
                <img loading="lazy" src={gearImg} alt="Experienced People" />
                <h5 className="mt-5 inner-title">
                  <Link to="/agility">Agility</Link>
                </h5>
                <p className="inner-lead px-lg-3">
                  Having upper management and principal involvement in our
                  projects allows us to make decisions and adjustments faster
                  than our competitors. We are not bogged down by beaurocracy
                  and internal regulations, we make decisions that are best for
                  our clients and we do so efficiently.
                </p>
              </div>
              <div className="col-lg-4 text-center work-box-3">
                <img loading="lazy" src={gadgetImg} alt="Experienced People" />
                <h5 className="mt-5">
                  <Link
                    to="/artificial-intelligence"
                    className="text-uppercase inner-title"
                    // onClick={() => setLgShow(true)}
                  >
                    Artificial Intelligence
                    <br />
                    Data Extraction
                  </Link>
                  {/* <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    className="modal-wrapper"
                  >
                    <Modal.Header
                      closeButton
                      style={{ borderBottom: "none" }}
                    />
                    <Modal.Body>
                      <div className="services-description-box mt-3">
                        <iframe
                          width="100%"
                          height="500px"
                          allowFullScreen
                          src="https://www.youtube.com/embed/3Nt67t0oBeM"
                          frameborder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          title="Gray Hawk ROW Analyzer"
                        ></iframe>
                      </div>
                    </Modal.Body>
                  </Modal> */}
                </h5>
                <p className="inner-lead px-lg-3">
                  <strong>
                    <u>This is the first-ever product of its kind</u>
                  </strong>{" "}
                  and will give you the edge to handle the oftentimes confusing
                  and stressful task of managing your organizations physical,
                  digital and historcial data. If you have mountains of old data
                  that needs to be entered into any database, please reach out
                  so we can show you how we can be a valuable asset to your
                  team.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 mx-auto mt-5 px-0">
            <h3 className="main-title">Why We Created Gray Hawk</h3>
            <p className="main-lead text-justify">
              Gray Hawk Land Solutions was formed with our clients in mind. We
              have taken years of knowledge and expertise within the Right of
              Way profession to form a comprehensive organization that you can
              count on for all your Right of Way needs.
            </p>
            <p className="main-lead text-justify">
              Gray Hawk can assist with the initial planning of projects all the
              way through cleanup and restoration. Our highly skilled and
              dedicated staff of professionals are accomplished in all aspects
              of project development and execution. We are committed to the
              details of each project, which ensures success and provides our
              clients with the most positive outcome.
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <Link to="#our-team" className="scroll-btn">
          <FaCaretDown
            className="text-white caret-down"
          />
        </Link>
        <p className="hidden-text mt-2">our team</p>
      </div>
    </>
  )
}

export default ChooseUs
